<template>
  <div class="spread">
    <basic-info :value="value" @input="update" />
    <main-address :value="value.address.data" @input="updateAddress" />
    <settings :value="value" :package-list="packageList" :selected-mvr="selectedMvrPackage" :selected-screening="selectedScreeningPackage" :client="true" @input="update" />
    <div class="module-form">
      <h3>DDI Assignments</h3>
      <h4>Client - DM Assignment</h4>
      <item-picker :search-results="managers" :selected="selectedManagers" placeholder="Type area manager name" required auto-select no-title @itemsChanged="updateClientManager" />
    </div>
  </div>
</template>

<script>
import { timeZones } from "@/util/timeZones";
import { BasicInfo, ItemPicker, MainAddress, Settings } from "@/components";
import { api } from "@/http/http.config";
export default {
  name: "ClientForm",
  components: { Settings, MainAddress, ItemPicker, BasicInfo },
  props: {
    title: {
      type: String,
      default: "Add New",
    },
    value: {
      type: Object,
      default: () => {},
    },
    managers: { type: Array, required: false, default: () => [] },
    selectedManagers: { type: Array, required: false, default: () => [] },
  },
  data: () => {
    return {
      flyout: false,
      timeZones: timeZones,
      isValid: Boolean,
      packageList: [],
    };
  },
  computed: {
    selectedMvrPackage() {
      return this.$props.value.mvrPackageName ? [{ id: this.$props.value.mvrPackageName, name: this.$props.value.mvrPackageName }] : [];
    },
    selectedScreeningPackage() {
      return this.$props.value.screeningPackageName ? [{ id: this.$props.value.screeningPackageName, name: this.$props.value.screeningPackageName }] : [];
    },
  },
  mounted() {
    api.get(`/onboarding/checkr/packages`).then((result) => {
      this.packageList = result.data.data.map((item) => {
        return { id: item.slug, name: item.slug };
      });
    });
  },
  methods: {
    updateClientManager(itemChangedEvent) {
      this.$emit("clientManagerChanged", itemChangedEvent);
    },
    update(value) {
      this.$emit("input", { ...this.$props.value, ...value });
    },
    updateAddress(value) {
      this.$emit("input", { ...this.$props.value, address: { data: { ...value } } });
    },
  },
};
</script>
